import React from "react";
import "./JbsMiddlePage.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import OptimizedImage from "../../../GlobalComponent/OptimizedImage/OptimizedImage";

function JbsMiddlePage() {
  return (
    <>
      <Container>
        <Row>
          <Col md={12} className="jbs-project-heading">
            | Our Projects
          </Col>
          <Col md={12} className="jbs-project-para">
            {" "}
            <ul>
              <li>
                We believe in a collaborative approach to design that focuses on
                your vision and lifestyle. Below is an overview of our process
                and showcased work.
              </li>
            </ul>{" "}
          </Col>
        </Row>
        <Row>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912322/wpl4zxswmqigp7lostpf.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912322/wpl4zxswmqigp7lostpf.png"
            /> */}
          </Col>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912322/dtsn5d783ymcglbp3vsy.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912322/dtsn5d783ymcglbp3vsy.png"
            /> */}
          </Col>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912328/pivto4zoap5tzmf3zmjj.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912328/pivto4zoap5tzmf3zmjj.png"
            /> */}
          </Col>
        </Row>
        <Row>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912317/bwqgogapsauxl9thy7vr.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912317/bwqgogapsauxl9thy7vr.png"
            /> */}
          </Col>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912325/je8wiskr5drw7de5lfkg.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912325/je8wiskr5drw7de5lfkg.png"
            /> */}
          </Col>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912329/mqdi7x9zdt1gbkecx9q8.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912329/mqdi7x9zdt1gbkecx9q8.png"
            /> */}
          </Col>
        </Row>
        <Row>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912317/quuy6ohhugjry0inw2ws.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912317/quuy6ohhugjry0inw2ws.png"
            /> */}
          </Col>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912324/qlwjzfzhged7x9zwtfto.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912324/qlwjzfzhged7x9zwtfto.png"
            /> */}
          </Col>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912325/ssi8llzmdwkl1keqqtmi.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912325/ssi8llzmdwkl1keqqtmi.png"
            /> */}
          </Col>
        </Row>
        <Row>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912319/dclmbqcscnomuyh4tnii.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912319/dclmbqcscnomuyh4tnii.png"
            /> */}
          </Col>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912324/ixnj9hcjiuw9d7sae6j4.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912324/ixnj9hcjiuw9d7sae6j4.png"
            /> */}
          </Col>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912329/wgjpiuwhbjvyrniutrzt.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912329/wgjpiuwhbjvyrniutrzt.png"
            /> */}
          </Col>
        </Row>
        <Row>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912320/lcnyufpmqu8px7qhgxvt.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912320/lcnyufpmqu8px7qhgxvt.png"
            /> */}
          </Col>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912317/gkxzcxl0wss63odowdcn.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912317/gkxzcxl0wss63odowdcn.png"
            /> */}
          </Col>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912326/ueercyttbinfzeoau06b.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912326/ueercyttbinfzeoau06b.png"
            /> */}
          </Col>
        </Row>
        <Row>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912322/twjr4u5oxdavepx0q5bi.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912322/twjr4u5oxdavepx0q5bi.png"
            /> */}
          </Col>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912317/npzvvh4lr0ssg6uccfdl.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912317/npzvvh4lr0ssg6uccfdl.png"
            /> */}
          </Col>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912327/wk0r0uohdrdzenrenbpu.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912327/wk0r0uohdrdzenrenbpu.png"
            /> */}
          </Col>
        </Row>
        <Row className="jbs-project-last-row">
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912322/k9kane5hwlangdvatuks.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912322/k9kane5hwlangdvatuks.png"
            /> */}
          </Col>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912320/nt7yg7tlebfvu3n6bwwe.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912320/nt7yg7tlebfvu3n6bwwe.png"
            /> */}
          </Col>
          <Col md={4} lg={4} className="jbs-image-gap">
            <OptimizedImage
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912328/gq5anudkh6y099w9lgeq.png"
              className="jbs-project-img"
              loading="lazy"
            />
            {/* <Image
              className="jbs-project-img"
              src="https://res.cloudinary.com/drny4cbec/image/upload/v1719912328/gq5anudkh6y099w9lgeq.png"
            /> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default JbsMiddlePage;
