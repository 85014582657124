import React from "react";
import RootRouter from "./RootRouter/RootRouter";
import InitialRouter from "./RootRouter/InitialRouter";

const App = () => {
  return (
    <>
      <RootRouter />
    </>
  );
};

export default App;
