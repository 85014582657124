import React from "react";
import JbsMainPage from "./JbsMainPage/JbsMainPage";
import JbsMiddlePage from "./JbsMiddlePage/JbsMiddlePage";

export default function JbsMainWork() {
  return (
    <div>
      <JbsMainPage />
      <JbsMiddlePage />
    </div>
  );
}
