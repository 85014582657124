import React from "react";
import Page1 from "./Page1/Page1";
import Page2 from "./Page2/Page2";
import Page4 from "./Page4/Page4";
import Page5 from "./Page5/Page5";
import AboutUsCard from "./AboutUsCard/AboutUsCard";
import CardPage from "../Service/Components/CardPage/CardPage";

const AboutUs = () => {
  return (
    <>
      <Page1 />
      <Page2 />
      <AboutUsCard />
      <Page4 />
      <Page5 />
    </>
  );
};
export default AboutUs;
