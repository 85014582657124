import React from "react";
import "./HorizontalLine.css";

const HorizontalLine = () => {
  return (
    <div className="horizontalContainer">
      <hr className="horizontalLine" />
    </div>
  );
};

export default HorizontalLine;
